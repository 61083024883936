import config from "@/config";
import http from "@/utils/request";

export default {
	give: {
		list: {
			url: `${config.API_URL}/market_activity/list`,
			name: "获取满赠活动列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/market_activity/store`,
			name: "新增编辑满赠活动",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/market_activity/detail`,
			name: "获取满赠活动详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/market_activity/del`,
			name: "删除满赠活动",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
	reduction: {
		list: {
			url: `${config.API_URL}/market_reduction/list`,
			name: "获取满减活动列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/market_reduction/store`,
			name: "新增编辑满减活动",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/market_reduction/detail`,
			name: "获取满减活动详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/market_reduction/del`,
			name: "删除满减活动",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
	coupon: {
		list: {
			url: `${config.API_URL}/coupon/index`,
			name: "获取优惠券列表",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/coupon/store`,
			name: "新增编辑优惠券",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/coupon/show`,
			name: "获取优惠券详情",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		updateStatus: {
			url: `${config.API_URL}/coupon/enable`,
			name: "启用禁用优惠券",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		mylist: {
			url: `${config.API_URL}/coupon/mylist`,
			name: "获取优惠券用户领取记录",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
	},
	homepage: {
		list: {
			url: `${config.API_URL}/homepage/list`,
			name: "获取首页礼包列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/homepage/store`,
			name: "新增编辑首页礼包",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/homepage/detail`,
			name: "获取首页礼包详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/homepage/del`,
			name: "删除首页礼包",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
	wealth: {
		list: {
			url: `${config.API_URL}/wealth/list`,
			name: "获取扫码测评列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/wealth/store`,
			name: "新增编辑扫码测评",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/wealth/detail`,
			name: "获取扫码测评详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/wealth/del`,
			name: "删除扫码测评",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		relevant: {
			url: `${config.API_URL}/wealth/relevant`,
			name: "获取属相列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
	collecting: {
		list: {
			url: `${config.API_URL}/card_collecting/list`,
			name: "获取集卡活动列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/card_collecting/store`,
			name: "新增编辑集卡活动",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/card_collecting/detail`,
			name: "获取集卡活动详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/card_collecting/del`,
			name: "删除集卡活动",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
};
