<template>
	<div class="image-content">
		<template v-if="multiple">
			<div v-for="(item, index) in file" class="imageBtn"
				:style="{ width: `${width || size}px`, height: `${height || size}px` }" :key="index">
				<el-image style="width: 100%; height: 100%" :initial-index="index" :preview-src-list="file" :src="item"
					fit="cover" preview-teleported />
				<div class="delete-icon">
					<span class="del" @click.stop="deleteIcon(index)"><el-icon><el-icon-delete /></el-icon></span>
				</div>
			</div>
			<template v-if="file.length < max">
				<div class="uploadBtn" :style="{ width: `${width || size}px`, height: `${height || size}px` }"
					@click="show">
					<slot name="upload">
						<el-icon>
							<el-icon-plus />
						</el-icon>
					</slot>
				</div>
			</template>
		</template>
		<template v-else>
			<div v-if="file" class="imageBtn"
				:style="{ margin: 0, width: `${width || size}px`, height: `${height || size}px` }">
				<el-image style="width: 100%; height: 100%" :src="file" :preview-src-list="[file]" fit="cover"
					preview-teleported></el-image>
				<div class="delete-icon">
					<span class="del" @click.stop="deleteImage"><el-icon><el-icon-delete /></el-icon></span>
				</div>
			</div>
			<div v-else class="uploadBtn"
				:style="{ margin: 0, width: `${width || size}px`, height: `${height || size}px` }" @click="show">
				<slot name="upload">
					<el-icon>
						<el-icon-plus />
					</el-icon>
				</slot>
			</div>
		</template>
	</div>
	<scImageSelect v-if="visiable" :max="max" :multiple="multiple" ref="imageSelectRef" @success="handleSuccess"
		@closed="visiable = false" />
</template>
<script setup>
	import { ref, nextTick, watch, computed } from "vue";
	import { useFormItem } from "element-plus";
	import { debugWarn } from "element-plus/es/utils/error";
	const emit = defineEmits(["update:modelValue", "success", "closed"]);
	const props = defineProps({
		modelValue: { type: [String, Array], default: "" },
		max: { type: [String, Number], default: 10 },
		size: { type: [String, Number], default: 60 },
		width: { type: [String, Number], default: 0 },
		height: { type: [String, Number], default: 0 },
		multiple: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
	})
	const imageSelectRef = ref(null);
	const visiable = ref(false);
	const { formItem } = useFormItem();
	const file = computed({
		get: () => props.modelValue,
		set: (val) => emit('update:modelValue', val),
	});
	watch(() => props.modelValue, (val) => {
		formItem?.validate('change').catch((err) => debugWarn(err))
	})
	const handleSuccess = (img) => {
		if (Array.isArray(img) && img.length > 0) {
			let temp = props.max - file.value.length;
			if (temp <= 0) return;
			file.value = file.value.concat(img.slice(0, temp));
		} else {
			file.value = img;
		}
	}
	const show = () => {
		if (props.disabled) return false;
		visiable.value = true;
		nextTick(() => {
			imageSelectRef.value.open();
		});
	}
	const deleteIcon = (index) => {
		file.value.splice(index, 1);
		file.value = [...file.value]
	}
	const deleteImage = () => {
		file.value = "";
	}
</script>

<style lang="scss" scoped>
	.image-content {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.imageBtn {
		width: 60px;
		height: 60px;
		border: 1px dotted #0000001a;
		margin-right: 15px;
		margin-bottom: 10px;
		display: inline-block;
		position: relative;
		cursor: pointer;
		user-select: none;

		&:hover .delete-icon {
			display: block;
		}

		.delete-icon {
			display: none;
			position: absolute;
			z-index: 1;
			right: 0;
			top: 0;

			span {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 25px;
				height: 25px;
				cursor: pointer;
				color: #fff;
			}

			span i {
				font-size: 12px;
			}

			.del {
				background: #f56c6c;
			}
		}
	}

	.uploadBtn {
		width: 60px;
		height: 60px;
		line-height: 60px;
		border: 1px dotted #0000001a;
		border-radius: 4px;
		margin-right: 15px;
		margin-bottom: 10px;
		background: #00000005;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.is-error {
		.uploadBtn {
			border-color: var(--el-color-danger) !important;
		}
	}
</style>
