// import config from "@/config";
import tool from "@/utils/tool";
import api from "@/api";

export default {
	namespaced: true,
	state: {
		Menu: tool.data.get("MENU") || [],
		Permissions: tool.data.get("PERMISSIONS") || [],
	},
	mutations: {
		SAVE_MENU(state, data) {
			state.Menu = data;
			tool.data.set("MENU", data);
		},
		clearMenu(state) {
			state.Menu = [];
			tool.data.remove("MENU");
		},
		SAVE_PERMISSIONS(state, data) {
			state.Permissions = data;
			tool.data.set("PERMISSIONS", data);
		},
	},
	actions: {
		async ApiGetMenu({ commit }) {
			console.log("触发")
			let res = await api.system.account.menu.post();
			if (res?.code === 1) {
				commit("SAVE_MENU", res.data.tree);
				commit("SAVE_PERMISSIONS", res.data.permission);
			}
		},
	},
	getters: {
		GetMenu(state) {
			return state.Menu || [];
		},
	},
};
