import config from "@/config";
import http from "@/utils/request";

export default {
	user: {
		list: {
			url: `${config.API_URL}/user/getUserList`,
			name: "用户列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		edit: {
			url: `${config.API_URL}/mini_user/store`,
			name: "编辑用户",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		detail: {
			url: `${config.API_URL}/mini_user/show`,
			name: "用户详情",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		record: {
			url: `${config.API_URL}/user/record`,
			name: "获取用户记录列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		export: {
			url: `${config.API_URL}/user/getUserList`,
			name: "导出用户列表",
			get: async function (params, config = {}) {
				return await http.get(this.url, params, config);
			},
		},
		recharge: {
			url: `${config.API_URL}/mini_user/recharge`,
			name: "赠送余额",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	member: {
		list: {
			url: `${config.API_URL}/member_level/index`,
			name: "获取会员列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		detail: {
			url: `${config.API_URL}/member_level/show`,
			name: "会员详情",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/member_level/store`,
			name: "新增/编辑会员等级",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	group: {
		list: {
			url: `${config.API_URL}/organization/list`,
			name: "获取分院列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/organization/store`,
			name: "新增/编辑分院",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/organization/del`,
			name: "删除分院",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	level: {
		list: {
			url: `${config.API_URL}/user_level/index`,
			name: "获取用户等级列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/user_level/store`,
			name: "新增/编辑用户等级",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		detail: {
			url: `${config.API_URL}/user_level/show`,
			name: "用户等级详情",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/user_level/del`,
			name: "删除用户等级",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		updataStatus: {
			url: `${config.API_URL}/user_level/enable`,
			name: "修改用户等级状态",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	identity: {
		list: {
			url: `${config.API_URL}/agent/index`,
			name: "获取合作伙伴列表",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		audit: {
			url: `${config.API_URL}/agent/enable`,
			name: "合作伙伴审核",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	config: {
		detail: {
			url: `${config.API_URL}/config/show`,
			name: "获取用户配置信息",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		save: {
			url: `${config.API_URL}/config/store`,
			name: "保存用户配置信息",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	lesson: {
		list: {
			url: `${config.API_URL}/course_category/list`,
			name: "课程分类列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/course_category/store`,
			name: "新增/编辑程分类",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},

		area: {
			url: `${config.API_URL}/course_area/list`,
			name: "课程领域列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		areaAddAndEdit: {
			url: `${config.API_URL}/course_area/store`,
			name: "新增/编辑课程领域",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		label: {
			url: `${config.API_URL}/label/list`,
			name: "课程标签列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		labelAddAndEdit: {
			url: `${config.API_URL}/label/store`,
			name: "新增/编辑课程标签",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		lecturerList: {
			url: `${config.API_URL}/lecturer/list`,
			name: "讲师列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		lecturerAddAndEdit: {
			url: `${config.API_URL}/lecturer/store`,
			name: "新增/编辑讲师",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		lecturerDetail: {
			url: `${config.API_URL}/lecturer/detail`,
			name: "讲师详情",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		lessonList: {
			url: `${config.API_URL}/course/list`,
			name: "线上课程列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		lessonDetail: {
			url: `${config.API_URL}/course/getDetail`,
			name: "线上课程详情",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/course/del`,
			name: "删除线上课程",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delChapter: {
			url: `${config.API_URL}/course/delChapter`,
			name: "删除线上课程章节",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		lecturerdel: {
			url: `${config.API_URL}/lecturer/del`,
			name: "删除讲师",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		lessondel: {
			url: `${config.API_URL}/offline_course/del`,
			name: "删除线下课程",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		outlineDetail: {
			url: `${config.API_URL}/offline_course/getDetail`,
			name: "线下课程详情",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		lessonAddAndEdit: {
			url: `${config.API_URL}/course/store`,
			name: "新增/编辑线上课程列表",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		storeCourseChapter: {
			url: `${config.API_URL}/course/storeCourseChapter`,
			name: "章节目录",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		offlineLessonList: {
			url: `${config.API_URL}/offline_course/list`,
			name: "线下课程列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		offlineLessonAddAndEdit: {
			url: `${config.API_URL}/offline_course/store`,
			name: "新增/编辑线下课程列表",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		card: {
			list: {
				url: `${config.API_URL}/card/list`,
				name: "复训卡列表",
				get: async function (params) {
					return await http.get(this.url, params);
				},
			},
			detail: {
				url: `${config.API_URL}/card/detail`,
				name: "复训卡详情",
				get: async function (params) {
					return await http.get(this.url, params);
				},
			},
			delete: {
				url: `${config.API_URL}/card/del`,
				name: "删除复训卡",
				get: async function (params) {
					return await http.get(this.url, params);
				},
			},
			addAndEdit: {
				url: `${config.API_URL}/card/store`,
				name: "新增/编辑复训卡",
				post: async function (params) {
					return await http.post(this.url, params);
				},
			},
			give: {
				url: `${config.API_URL}/user/giveCard`,
				name: "赠送卡项",
				post: async function (params) {
					return await http.post(this.url, params);
				},
			},
		},
	},
	formula: {
		list: {
			url: `${config.API_URL}/formula/list`,
			name: "配方列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/formula/store`,
			name: "新增/编辑配方",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		detail: {
			url: `${config.API_URL}/formula/detail`,
			name: "配方详情",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		proposeAddAndEdit: {
			url: `${config.API_URL}/formula/formulaProposeStore`,
			name: "新增/编辑建议配方",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/formula/del`,
			name: "删除配方",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	wiki: {
		list: {
			url: `${config.API_URL}/wiki/list`,
			name: "百科列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/wiki/store`,
			name: "新增/编辑百科",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		detail: {
			url: `${config.API_URL}/wiki/detail`,
			name: "百科详情",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/wiki/del`,
			name: "删除百科",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	formulacate: {
		list: {
			url: `${config.API_URL}/category/list`,
			name: "配方/百科分类列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/category/store`,
			name: "新增/编辑配方/百科分类",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/category/del`,
			name: "删除分类",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	formulaout: {
		list: {
			url: `${config.API_URL}/formula/getFormulaStockFlow`,
			name: "库存流水列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/formula/operateFormulaStock`,
			name: "新增/编辑库存流水",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/formula/getFormulaStockFlow`,
			name: "删除分类",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	advert: {
		list: {
			url: `${config.API_URL}/advert/list`,
			name: "广告列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/advert/store`,
			name: "新增/编辑广告",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/advert/del`,
			name: "删除广告",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	kingkong: {
		list: {
			url: `${config.API_URL}/kingkong/list`,
			name: "金刚区列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/kingkong/store`,
			name: "新增/编辑金刚区",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	banner: {
		list: {
			url: `${config.API_URL}/banner/list`,
			name: "banner列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/banner/store`,
			name: "新增/编辑banner",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/banner/del`,
			name: "删除banner",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	problem: {
		list: {
			url: `${config.API_URL}/problem/list`,
			name: "问题列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/problem/store`,
			name: "新增/编辑问题",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/problem/del`,
			name: "删除问题",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	agreement: {
		list: {
			url: `${config.API_URL}/agreement/list`,
			name: "协议列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/agreement/store`,
			name: "新增/编辑协议",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	userlist: {
		list: {
			url: `${config.API_URL}/user/getUserList`,
			name: "用户列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
	},
	withdrawa: {
		list: {
			url: `${config.API_URL}/withdrawal/index`,
			name: "提现列表",
			post: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/withdrawal/store`,
			name: "提现审核",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		detail: {
			url: `${config.API_URL}/withdrawal/show`,
			name: "提现详情",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		getWithdrawalConfig: {
			url: `${config.API_URL}/withdrawal/getWithdrawalConfig`,
			name: "获取提现配置",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		withdrawalConfig: {
			url: `${config.API_URL}/withdrawal/withdrawalConfigStore`,
			name: "提现配置",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	platformAssets: {
		list: {
			url: `${config.API_URL}/order/getPlatformAssets`,
			name: "资产与佣金",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
	},
	getDistributionList: {
		list: {
			url: `${config.API_URL}/order/getDistributionList`,
			name: "分销列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
	},
	getIncomeList: {
		list: {
			url: `${config.API_URL}/order/getIncomeList`,
			name: "收入明细",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
	},
	order: {
		list: {
			url: `${config.API_URL}/order/index`,
			name: "订单列表",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		store: {
			url: `${config.API_URL}/order/paymentSettlement`,
			name: "订单结款",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
};
