import config from "@/config";
import http from "@/utils/request";

export default {
	article: {
		index: {
			list: {
				url: `${config.API_URL}/cms_article/index`,
				name: "获取文章列表",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			addAndEdit: {
				url: `${config.API_URL}/cms_article/store`,
				name: "新增编辑文章",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			detail: {
				url: `${config.API_URL}/cms_article/show`,
				name: "获取文章详情",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			updataStatus: {
				url: `${config.API_URL}/cms_article/enable`,
				name: "启用禁用文章",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			delete: {
				url: `${config.API_URL}/cms_article/del`,
				name: "删除文章",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			bind: {
				url: `${config.API_URL}/cms_article/relation`,
				name: "绑定商品",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			unbind: {
				url: `${config.API_URL}/cms_article/unrelation`,
				name: "取消绑定",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
		},
		category: {
			list: {
				url: `${config.API_URL}/cms_category/index`,
				name: "获取文章分类列表",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			addAndEdit: {
				url: `${config.API_URL}/cms_category/store`,
				name: "新增编辑文章分类",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			detail: {
				url: `${config.API_URL}/cms_category/show`,
				name: "获取文章分类详情",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			updataStatus: {
				url: `${config.API_URL}/cms_category/enable`,
				name: "启用禁用文章分类",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			delete: {
				url: `${config.API_URL}/cms_category/del`,
				name: "删除文章分类",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
		},
	},
};
