<template>
	<el-input :size="size" :placeholder="placeholder" :value="modelValue" readonly
		@click="visible = !visible"></el-input>
	<el-dialog title="选择链接" v-model="visible" align-center :width="1000" destroy-on-close @closed="$emit('closed')">
		<el-container>
			<el-aside width="171px" style="height:470px">
				<el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick">
					<template #default="{ node, data }">
						<span :class="{ empty: true, checked: data?.components === selectComponents }">
							<span>{{ node.label }}</span>
						</span>
					</template>
				</el-tree>
			</el-aside>
			<el-main>
				<template v-if="selectComponents">
					<component :is="selectComponents" v-model="url"></component>
				</template>
				<template v-else>
					<div class="panel">
						请在左侧选择路径分类
					</div>
				</template>
			</el-main>
		</el-container>
		<template #footer>
			<el-button @click="visible = false">取 消</el-button>
			<el-button type="danger" :loading="isSaveing" @click="clear">清 空</el-button>
			<el-button type="primary" :loading="isSaveing" @click="submit()">保 存</el-button>
		</template>
	</el-dialog>
</template>

<script>
	import Component from "./components"
	export default {
		props: {
			modelValue: {
				type: String,
				default: ""
			},
			size: {
				type: String,
				default: "default"
			},
			placeholder: {
				type: String,
				default: "链接"
			}
		},
		components: {
			...Component
		},
		data() {
			return {
				visible: false,
				isSaveing: false,
				url: "",
				selectComponents: "Link",
				data: [
					// {
					// 	label: '商城页面',
					// 	children: [
					// 		{
					// 			label: '商城链接',
					// 			components: "Link"
					// 		},
					// 	],
					// },
					{
						label: '商城链接',
						components: "Link"
					},
					{
						label: '商品页面',
						components: "Goods"
					},
					{
						label: '商品分类',
						components: "Cate"
					},
					{
						label: '活动页面',
						components: "Activity"
					},
					// {
					// 	label: '讲师页面',
					// 	components: "Teacher"
					// }
				],
				defaultProps: {
					children: 'children',
					label: 'label',
				}
			}
		},
		mounted() {
		},
		methods: {
			handleNodeClick(data) {
				if (!data.components) return
				this.selectComponents = data.components
			},
			//表单提交方法
			submit() {
				this.isSaveing = true
				this.$emit('update:modelValue', this.url);
				this.isSaveing = false
				this.selectComponents = "Offline"
				this.visible = false
			},
			clear() {
				this.isSaveing = true
				this.$emit('update:modelValue', '');
				this.isSaveing = false
				this.selectComponents = "Offline"
				this.visible = false
			}
		}
	}
</script>

<style lang="scss" scoped>
	.panel {
		height: 470px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		color: #b2b2b2;
	}

	.checked {
		color: var(--el-color-primary);
	}

	.empty {
		font-weight: bold;
	}

</style>
