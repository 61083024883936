import config from "@/config";
import http from "@/utils/request";

export default {
	user: {
		list: {
			url: `${config.API_URL}/agent/index`,
			name: "获取分销列表",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		detail: {
			url: `${config.API_URL}/agent/show`,
			name: "分销详情",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		updataStatus: {
			url: `${config.API_URL}/agent/enable`,
			name: "修改分销员状态",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/agent/del`,
			name: "删除分销员",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		channel: {
			url: `${config.API_URL}/agent/channelList`,
			name: "获取分销渠道列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
	},
	level: {
		list: {
			url: `${config.API_URL}/agent/channelLevelList`,
			name: "获取分销等级列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/agent_level/store`,
			name: "新增/编辑分销等级",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		detail: {
			url: `${config.API_URL}/agent_level/show`,
			name: "分销等级详情",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/agent_level/del`,
			name: "删除分销等级",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		updataStatus: {
			url: `${config.API_URL}/agent_level/enable`,
			name: "修改分销等级状态",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	config: {
		detail: {
			url: `${config.API_URL}/config/show`,
			name: "获取分销配置信息",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		save: {
			url: `${config.API_URL}/config/store`,
			name: "保存分销配置信息",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	meeting: {
		list: {
			url: `${config.API_URL}/meeting/index`,
			name: "获取会议列表",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		detail: {
			url: `${config.API_URL}/meeting/show`,
			name: "会议详情",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/meeting/store`,
			name: "新增/编辑会议",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		settlement: {
			url: `${config.API_URL}/meeting/settlement`,
			name: "结算会议",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		records: {
			url: `${config.API_URL}/meeting/records`,
			name: "会议奖励记录",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	material: {
		list: {
			url: `${config.API_URL}/filelibrary/getShareGroupList`,
			name: "获取素材列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/filelibrary/setShareGroup`,
			name: "新增/编辑素材",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/filelibrary/deleteShareGroup`,
			name: "删除素材",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		detail: {
			url: `${config.API_URL}/filelibrary/getShareGroup`,
			name: "获取素材详情",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
	},
	feedback: {
		list: {
			url: `${config.API_URL}/user/recommendList`,
			name: "获取意见反馈列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
	},
	setting: {
		detail: {
			url: `${config.API_URL}/config/show`,
			name: "获取用户配置信息",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		save: {
			url: `${config.API_URL}/config/store`,
			name: "保存用户配置信息",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	navigation: {
		list: {
			url: `${config.API_URL}/navigation/list`,
			name: "获取金刚区列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/navigation/store`,
			name: "编辑金刚区",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/navigation/del`,
			name: "删除金刚区",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	advert: {
		list: {
			url: `${config.API_URL}/image_area/list`,
			name: "获取广告列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/image_area/store`,
			name: "编辑广告",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/image_area/del`,
			name: "删除广告",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
};
