<template>
	<el-dialog title="选择商品" v-model="visible" align-center :width="800" destroy-on-close @closed="$emit('closed')">
		<scTable v-loading="isLoading" ref="table" :data="tableData" :staticTotal="total" height="470px"
			:pageSize="queryInfo.pageSize" stripe @pagination-change="paginationChange"
			@page-size-change="pageSizeChange" @selection-change="selectionChange" row-key="id">
			<el-table-column label="#" type="selection" width="55" align="center" reserve-selection></el-table-column>
			<el-table-column label="ID" prop="id" width="100" align="center"></el-table-column>
			<el-table-column label="商品图" width="150" align="center">
				<template #default="scope">
					<div class="cell-image">
						<el-avatar :src="scope.row.image"></el-avatar>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="商品名称" prop="title" align="center" min-width="150"></el-table-column>
		</scTable>
		<template #footer>
			<el-button @click="visible = false">取 消</el-button>
			<el-button type="primary" :loading="isSaveing" @click="submit()">保 存</el-button>
		</template>
	</el-dialog>
</template>
<script>
	export default {
		emits: ['success', 'closed'],
		data() {
			return {
				tableData: [],
				total: 0,
				selection: [],
				visible: false,
				isSaveing: false,
				queryInfo: {
					page: 1,
					size: 10,
					keywords: ""
				},
				isLoading: false
			}
		},
		mounted() {
			this.getDataList()
		},
		methods: {
			//显示
			open() {
				this.visible = true;
				return this
			},
			//! 分页功能
			paginationChange(page) {
				this.queryInfo.pageNo = page
				this.getDataList()
			},
			//! 页码切换
			pageSizeChange(size) {
				this.queryInfo.pageSize = size
				this.getDataList()
			},
			selectionChange(selection) {
				this.selection = selection;
			},
			submit() {
				this.isSaveing = true
				this.$emit('success', this.selection);
				this.visible = false;
				this.isSaveing = false
			},
			async getDataList() {
				this.isLoading = true
				let res = await this.$API.goods.goods.list.get(this.queryInfo)
				this.isLoading = false
				if (res?.code === 1) {
					this.tableData = res?.data?.data || []
					this.total = res?.data?.total || 0
				}
			},
		}
	}
</script>
