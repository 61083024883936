<template>
	<div>
		<el-upload action="#" :show-file-list="false" :auto-upload="false"
			:on-change="(file, fileList) => { handleVideo(file, fileList) }">
			<div class="upload">
				<div class="upload-btn" v-if="currentVal && !flag">
					<el-icon class="deleteIcon" @click.stop="delData">
						<el-icon-delete />
					</el-icon>
					<sc-video :src="currentVal"></sc-video>
				</div>
				<div class="upload-btn" v-else v-loading="flag" element-loading-text="上传中...">
					<el-icon>
						<el-icon-plus />
					</el-icon>
				</div>
				<div class="upload-progress" v-if="flag">
					<el-progress :percentage="percent" striped striped-flow :show-text="false" />
				</div>
			</div>

		</el-upload>
	</div>
</template>

<script setup>
	import { uploadFileSlices } from "@/hooks/useUpload"
	import scVideo from "@/components/scVideo";
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { computed, ref } from "vue"
	const props = defineProps({
		modelValue: {
			type: String,
			default: '',
		},
	});
	const emit = defineEmits(['update:modelValue']);
	const currentVal = computed({
		get: () => props.modelValue,
		set: (val) => emit('update:modelValue', val)
	})
	const flag = ref(false);
	const percent = ref(0);
	const handleVideo = (file) => {
		uploadFileSlices(file, {
			onTaskReady: () => {
				flag.value = true
			},
			success: (data) => {
				flag.value = false
				currentVal.value = data.url
			},
			onProgress: (progressData) => {
				percent.value = Math.floor(progressData.percent * 100)
			}
		})
	}
	const delData = () => {
		ElMessageBox.confirm(
			`确定删除当前视频吗？`,
			'提示'
		).then(() => {
			currentVal.value = ''
			ElMessage({
				type: 'success',
				message: '操作成功',
			})
		}).catch(() => { })
	}
</script>

<style lang="scss" scoped>
	.upload {
		width: 300px;

		&-btn {
			width: 100%;
			height: 178px;
			border: 1px dashed #e2e2e2;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
		}

		&-progress {
			margin-top: 6px;
		}
	}


	.deleteIcon {
		position: absolute;
		top: 0;
		right: 0;
		width: 25px;
		height: 25px;
		padding: 0 5px;
		background: var(--el-color-primary);
		color: #ffffff;
		z-index: 107;
	}

	::v-deep(.sc-video) {
		height: 100% !important;
	}
</style>
