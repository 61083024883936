import { createVersionPolling } from "version-polling";
createVersionPolling({
	appETagKey: "__APP_ETAG__",
	pollingInterval: 5 * 1000, // 单位为毫秒
	silent: process.env.NODE_ENV === "development", // 开发环境下不检测
	onUpdate: (self) => {
		// 当检测到有新版本时，执行的回调函数，可以在这里提示用户刷新页面
		const result = confirm("页面有更新，点击确定刷新页面！");
		if (result) {
			self.onRefresh();
		} else {
			self.onCancel();
		}
		// 强制更新可以用alert
		// alert('有新版本，请刷新页面');
	},
});
