import config from "@/config";
import http from "@/utils/request";

export default {
	device: {
		category: {
			list: {
				url: `${config.API_URL}/device_category/list`,
				name: "获取设备分类列表",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
			addAndEdit: {
				url: `${config.API_URL}/device_category/store`,
				name: "新增编辑设备分类",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			delete: {
				url: `${config.API_URL}/device_category/del`,
				name: "删除设备分类",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
		},
		device: {
			list: {
				url: `${config.API_URL}/device/list`,
				name: "获取设备列表",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
			addAndEdit: {
				url: `${config.API_URL}/device/store`,
				name: "新增编辑设备",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			delete: {
				url: `${config.API_URL}/device/del`,
				name: "删除设备",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
			detail: {
				url: `${config.API_URL}/device/detail`,
				name: "获取设备详情",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
		},
	},
	activity: {
		list: {
			url: `${config.API_URL}/navigation/list`,
			name: "获取活动列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/navigation/store`,
			name: "新增编辑活动",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/navigation/del`,
			name: "删除活动",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/navigation/detail`,
			name: "获取活动详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
};
