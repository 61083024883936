import config from "@/config";
import http from "@/utils/request";

export default {
	activity: {
		list: {
			url: `${config.API_URL}/activity/list`,
			name: "获取活动列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		detail: {
			url: `${config.API_URL}/activity/detail`,
			name: "获取活动详情",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		save: {
			url: `${config.API_URL}/activity/store`,
			name: "新增/编辑活动",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/activity/del`,
			name: "删除活动",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		record: {
			url: `${config.API_URL}/order/record`,
			name: "获取活动记录",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		exportRecord: {
			url: `${config.API_URL}/order/record`,
			name: "导出活动记录",
			post: async function (params) {
				return await http.post(this.url, params, {
					responseType: "blob",
				});
			},
		},
	},
};
