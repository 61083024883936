import config from "@/config";
import http from "@/utils/request";

export default {
	menu: {
		list: {
			url: `${config.API_URL}/menu/index`,
			name: "获取菜单列表",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/menu/store`,
			name: "新增/编辑菜单",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/menu/del`,
			name: "删除菜单",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
	},
	account: {
		list: {
			url: `${config.API_URL}/user/index`,
			name: "获取账号列表",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/user/store`,
			name: "新增/编辑账号",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/user/del`,
			name: "删除账号",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/user/show`,
			name: "获取账号详情",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		menu: {
			url: `${config.API_URL}/user/menu`,
			name: "获取账号菜单",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
	},
	role: {
		list: {
			url: `${config.API_URL}/role/index`,
			name: "获取角色列表",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/role/store`,
			name: "编辑角色",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		delete: {
			url: `${config.API_URL}/role/del`,
			name: "删除角色",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
	},
	log: {
		url: `${config.API_URL}/sys_log/index`,
		name: "日志列表",
		post: async function (data) {
			return await http.post(this.url, data);
		},
	},
	config: {
		show: {
			url: `${config.API_URL}/config/show`,
			name: "获取配置信息",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		save: {
			url: `${config.API_URL}/config/store`,
			name: "保存配置信息",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	invited: {
		list: {
			url: `${config.API_URL}/invite/index`,
			name: "邀请列表",
			get: async function () {
				return await http.get(this.url);
			},
		},
		update: {
			url: `${config.API_URL}/invite/update`,
			name: "修改邀请",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
	},
	statistics: {
		detail: {
			url: `${config.API_URL}/order/getPlatformAssets`,
			name: "获取统计详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		distribution: {
			url: `${config.API_URL}/order/getChannelDistribution`,
			name: "获取渠道排行榜",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		export: {
			url: `${config.API_URL}/order/getChannelDistribution`,
			name: "导出渠道排行榜",
			get: async function (data = {}) {
				return await http.get(this.url, data, { responseType: "blob" });
			},
		},
		graph: {
			url: `${config.API_URL}/order/graph`,
			name: "导出渠道排行榜",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
};
