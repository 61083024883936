<template>
	<div class="goods-box">
		<draggable class="list-group" :group="{
			name: 'imageList',
			pull: false,
			put: false,
		}" item-key="id" v-model="value" :animation="340">
			<template #item="{ element, index }">
				<div class="item">
					<el-image style="width: 100%;height: 100%;" :src="element.image" :initial-index="index"
						:preview-src-list="previewSrcList" fit="cover"></el-image>
					<el-icon class="delete" @click="deleteData(index)">
						<el-icon-circle-close-filled />
					</el-icon>
				</div>
			</template>
		</draggable>
		<div class="add-item item" @click="choose">
			<el-icon>
				<el-icon-plus />
			</el-icon>
		</div>
	</div>
	<Goods v-if="dialog.save" ref="GoodsDialog" @success="handleSuccess" @closed="dialog.save = false"></Goods>
</template>

<script>
import draggable from "vuedraggable"
import Goods from "./Goods"
export default {
	props: {
		modelValue: { type: Array, default: () => [] },
	},
	components: {
		Goods,
		draggable
	},
	data() {
		return {
			dialog: {
				save: false
			},
			value: []
		}
	},
	watch: {
		modelValue: {
			handler(val) {
				this.value = val
			},
			deep: true,
			immediate: true
		},
		value(val) {
			this.$emit('update:modelValue', val)
		},
	},
	computed: {
		previewSrcList({ value }) {
			return value.map(item => item.image)
		}
	},
	methods: {
		choose() {
			this.dialog.save = true
			this.$nextTick(() => {
				this.$refs.GoodsDialog.open()
			})
		},
		handleSuccess(data) {
			let tempData = this.$TOOL.objCopy(this.value)
			tempData = tempData.concat(data)
			tempData = this.$TOOL.uniqueFunc(tempData, 'id')
			this.value = tempData
		},
		deleteData(index) {
			this.value.splice(index, 1)
		}
	}
}
</script>

<style lang="scss" scoped>
.goods-box {
	display: flex;
	flex-wrap: wrap;

	.list-group {
		display: flex;
		flex-wrap: wrap;
	}

	.item {
		position: relative;
		width: 80px;
		height: 80px;
		margin-bottom: 20px;
		margin-right: 12px;
		user-select: none;

		.delete {
			position: absolute;
			right: -10px;
			top: -10px;
			color: #999;
			font-size: 24px;
			cursor: pointer;
		}
	}

	.add-item {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #f7f7f7;
		cursor: pointer;
	}
}
</style>
