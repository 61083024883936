<template>
	<svg t="1686305483366" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2047"
		width="200" height="200">
		<path
			d="M95.32955347 297.15902902h175.45345964v394.63159577H95.32955347V297.15902902z m438.55800085 0h175.40302747v394.63159577h-175.40302747V297.15902902z m-219.27900042 0h175.40302748v394.63159577h-175.40302748V297.15902902z m438.55800086 0h175.40302746v394.63159577h-175.40302746V297.15902902z"
			p-id="2048"></path>
		<path
			d="M1016.32152811 779.49213851H7.67847189V209.40708312h1008.64305622v570.08505539z m-964.76708328-43.87597294h920.89111034V253.28305608H51.55444483v482.38354164z"
			p-id="2049"></path>
	</svg>
</template>
