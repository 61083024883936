import config from "@/config";
import http from "@/utils/request";

export default {
	upload: {
		url: `${config.API_URL}/filelibrary/image`,
		name: "文件上传",
		post: async function (data, config = {}) {
			return await http.post(this.url, data, config);
		},
	},
	uploadFile: {
		url: `${config.API_URL}/uploadFile`,
		name: "附件上传",
		post: async function (data, config = {}) {
			return await http.post(this.url, data, config);
		},
	},
	exportFile: {
		url: `${config.API_URL}/fileExport`,
		name: "导出附件",
		get: async function (data, config = {}) {
			return await http.get(this.url, data, config);
		},
	},
	importFile: {
		url: `${config.API_URL}/fileImport`,
		name: "导入附件",
		post: async function (data, config = {}) {
			return await http.post(this.url, data, config);
		},
	},
	file: {
		list: {
			url: `${config.API_URL}/filelibrary/getList`,
			name: "获取文件列表",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		group: {
			list: {
				url: `${config.API_URL}/filelibrary/imageGroupList`,
				name: "获取文件分组列表",
				post: async function (params) {
					return await http.post(this.url, params);
				},
			},
			save: {
				url: `${config.API_URL}/filelibrary/addImageGroup`,
				name: "新增/编辑文件分组",
				post: async function (params) {
					return await http.post(this.url, params);
				},
			},
			delete: {
				url: `${config.API_URL}/filelibrary/deleteImageGroup`,
				name: "删除文件分组",
				post: async function (params) {
					return await http.post(this.url, params);
				},
			},
		},
		delete: {
			url: `${config.API_URL}/filelibrary/delete`,
			name: "删除文件",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
};
