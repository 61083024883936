// import config from "@/config";
import tool from "@/utils/tool";
import api from "@/api";

export default {
	namespaced: true,
	state: {
		UserInfo: tool.data.get("USER_INFO"),
		AgentId: tool.data.get("AGENT_ID"),
		StoreId: tool.data.get("STORE_ID"),
	},
	mutations: {
		SAVE_USER_INFO(state, data) {
			delete data.password;
			state.UserInfo = data;
			tool.data.set("USER_INFO", data);
			if (Object.prototype.hasOwnProperty.call(data, "agentId")) {
				state.AgentId = data.agentId;
				tool.data.set("AGENT_ID", data.agentId);
			}
			if (Object.prototype.hasOwnProperty.call(data, "storeId")) {
				tool.data.set("STORE_ID", data.storeId);
				state.StoreId = data.storeId;
			}
		},
		clearUserInfo(state) {
			state.UserInfo = "";
			state.AgentId = "";
			state.StoreId = "";
			tool.data.remove("USER_INFO");
			tool.data.remove("AGENT_ID");
			tool.data.remove("STORE_ID");
		},
	},
	actions: {
		async GetLoginInfo({ commit, state }) {
			const { id } = state.UserInfo;
			let res = await api.system.account.detail.post({ id });
			if (res?.code === 1) {
				commit("SAVE_USER_INFO", res.data);
			}
		},
	},
	getters: {
		GetUserInfo(state) {
			return state.UserInfo || {};
		},
	},
};
