import config from "@/config";
import http from "@/utils/request";

export default {
	list: {
		url: `${config.API_URL}/order/index`,
		name: "订单列表",
		post: async function (params) {
			return await http.post(this.url, params);
		},
	},
	store: {
		url: `${config.API_URL}/order/paymentSettlement`,
		name: "订单结款",
		post: async function (params) {
			return await http.post(this.url, params);
		},
	},
	audit: {
		url: `${config.API_URL}/order/refundOrder`,
		name: "售后审核",
		post: async function (params) {
			return await http.post(this.url, params);
		},
	},
	goods: {
		list: {
			url: `${config.API_URL}/order/getGoodsOrderList`,
			name: "获取订单列表",
			post: async function (params, config = {}) {
				return await http.post(this.url, params, config);
			},
		},
		detail: {
			url: `${config.API_URL}/order/getGoodsOrderDetail`,
			name: "订单详情",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		updataStatus: {
			url: `${config.API_URL}/order/enable`,
			name: "修改订单状态",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		store: {
			url: `${config.API_URL}/order/paymentSettlement`,
			name: "订单结款",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		cancel: {
			url: `${config.API_URL}/order/cancelGoodsOrder`,
			name: "取消订单",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		update: {
			url: `${config.API_URL}/order/updateGoodsOrder`,
			name: "修改订单信息",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	express: {
		list: {
			url: `${config.API_URL}/order/express_list`,
			name: "获取物流公司",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		send: {
			url: `${config.API_URL}/order/express`,
			name: "订单发货",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
	return: {
		list: {
			url: `${config.API_URL}/return_order/getReturnOrder`,
			name: "售后订单列表",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
		detail: {
			url: `${config.API_URL}/return_order/getReturnOrderDetail`,
			name: "售后订单详情",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		audit: {
			url: `${config.API_URL}/return_order/handleReturnOrder`,
			name: "售后订单审核",
			post: async function (params) {
				return await http.post(this.url, params);
			},
		},
	},
};
