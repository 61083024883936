import config from "@/config";
import http from "@/utils/request";

export default {
	list: {
		url: `${config.API_URL}/renovation/index`,
		name: "获取自定义装修列表",
		get: async function (data = {}) {
			return await http.get(this.url, data);
		},
	},
	addAndEdit: {
		url: `${config.API_URL}/renovation/store`,
		name: "新增编辑自定义装修列表",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		},
	},
	detail: {
		url: `${config.API_URL}/renovation/detail`,
		name: "获取自定义装修详情",
		get: async function (data = {}) {
			return await http.get(this.url, data);
		},
	},
};
