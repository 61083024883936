<template>
	<el-dialog :title="titleMap[mode]" v-model="visible" align-center :width="600" destroy-on-close
		@closed="$emit('closed')">
		<el-form :model="form" :rules="rules" :disabled="mode == 'show'" ref="dialogForm" label-width="130px"
			label-position="left">
			<el-form-item label="分类名称" prop="group_name">
				<el-input v-model="form.group_name" placeholder="请输入分类名称" clearable></el-input>
			</el-form-item>
		</el-form>
		<template #footer>
			<el-button @click="visible = false">取 消</el-button>
			<el-button type="primary" :loading="isSaveing" @click="submit()">
				确 认
			</el-button>
		</template>
	</el-dialog>
</template>

<script setup>
	import api from "@/api"
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { ref, reactive } from "vue"
	const emit = defineEmits(["success", "closed"]);
	const mode = ref('add');
	const titleMap = reactive({
		add: "新增分类",
		edit: "编辑分类",
	});
	const visible = ref(false);
	const isSaveing = ref(false);
	const form = ref({
		group_name: ''
	})
	const rules = reactive({
		group_name: [{ required: true, message: "请输入分类名称" }],
	})
	const open = (row = "add", data = {}) => {
		if (data.id) {
			form.value = Object.assign(form.value, data)
		}
		mode.value = row;
		visible.value = true;
	}
	const dialogForm = ref(null);
	const submit = () => {
		dialogForm.value.validate(async (valid) => {
			if (valid) {
				const buildData = {
					...form.value
				}
				isSaveing.value = true;
				let res = await api.common.file.group.save.post(buildData);
				isSaveing.value = false;
				if (res?.code === 1) {
					emit("success");
					visible.value = false;
					ElMessage.success("操作成功");
				} else {
					ElMessageBox(res.message, "提示", { type: "error" });
				}
			} else {
				return false;
			}
		});
	}
	defineExpose({ open })
	// export default {
	// 	emits: ["success", "closed"],
	// 	data() {
	// 		return {
	// 			mode: "add",
	// 			titleMap: {
	// 				add: "新增分类",
	// 				edit: "编辑分类",
	// 			},
	// 			visible: false,
	// 			isSaveing: false,
	// 			//表单数据
	// 			form: {
	// 				group_name: "",
	// 			},
	// 			//验证规则
	// 			rules: {
	// 				group_name: [{ required: true, message: "请输入分类名称" }],
	// 			},
	// 		};
	// 	},
	// 	mounted() { },
	// 	methods: {
	// 		//显示
	// 		open(mode = "add") {
	// 			this.mode = mode;
	// 			this.visible = true;
	// 			return this;
	// 		},
	// 		//表单提交方法
	// 		submit() {
	// 			this.$refs.dialogForm.validate(async (valid) => {
	// 				if (valid) {

	// 					this.isSaveing = true;
	// 					var res = await this.$API.user.withdrawa.addAndEdit.post();
	// 					this.isSaveing = false;
	// 					if (res?.code === 1) {
	// 						this.$emit("success", this.form, this.mode);
	// 						this.visible = false;
	// 						this.$message.success("操作成功");
	// 					} else {
	// 						this.$alert(res.message, "提示", { type: "error" });
	// 					}
	// 				} else {
	// 					return false;
	// 				}
	// 			});
	// 		},
	// 		//表单注入数据
	// 		setData(data) {
	// 			Object.assign(this.form, data);
	// 		},
	// 	},
	// };
</script>

<style></style>
