
import COS from "cos-js-sdk-v5";
import { v4 as uuidv4 } from "uuid";
import sysConfig from "@/config";
import { ElMessage } from 'element-plus'
const COSClient = new COS({
	SecretId: sysConfig.SECRETID,
	SecretKey: sysConfig.SECRETKEY,
})
function beforeUploadVideo(file) {
	if (
		[
			"video/mp4",
			"video/ogg",
			"video/flv",
			"video/avi",
			"video/wmv",
			"video/rmvb",
		].indexOf(file.type) === -1
	) {
		ElMessage({
			type: 'error',
			message: '请上传正确的视频格式',
		})
		return false;
	}
	return true;
}
export const uploadFileSlices = (uploadFile, { success, fail, onTaskReady, onProgress, onFileFinish }) => {
	const res = beforeUploadVideo(uploadFile.raw);
	if (!res) fail(false);
	const type = uploadFile.raw.type.split("/")[1];
	const uuid = () => uuidv4().replace(/-/g, "");
	COSClient.uploadFile(
		{
			Bucket: sysConfig.BUCKET, /* 填入您自己的存储桶，必须字段 */
			Region: sysConfig.REGION, /* 存储桶所在地域，例如ap-beijing，必须字段 */
			/* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
			Body: uploadFile.raw, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
			Key: `video/${new Date().format('yyyyMMdd')}/${uuid()}.${type}`,
			SliceSize: 1024 * 1024 * 10, /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
			onTaskReady: () => {
				if (typeof (onTaskReady) === 'function') {
					return onTaskReady()
				}
			},
			// 百分比
			onProgress: (progressData) => {
				if (typeof (onProgress) === 'function') {
					return onProgress(progressData)
				}
			},
			// 上传完成
			onFileFinish: (err, data, options) => {
				if (typeof (onFileFinish) === 'function') {
					return onFileFinish(err, data, options)
				}
				success({
					url: "https://" + data.Location,
				})
			},
		},
		function (err, data) {
			if (!!err) {
				ElMessage({
					type: 'error',
					message: '视频上传失败，请重新上传！',
				})
			}
		}
	);
};
