<template>
	<svg t="1686305528743" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2047"
		width="200" height="200">
		<path
			d="M99.64510749 299.38418759H924.25507292v173.53642715H99.64510749V299.38418759z m0 216.95796633H924.25507292v173.58633698H99.64510749v-173.58633698z"
			p-id="2048"></path>
		<path
			d="M1011.09815123 776.7216594H12.90184877V212.54110929h998.19630246v564.18055011z m-954.7747633-43.42153915h911.35322414V255.96264844H56.32338793v477.38738163z"
			p-id="2049"></path>
	</svg>
</template>
