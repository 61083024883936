import config from "@/config";
import http from "@/utils/request";

export default {
	investor: {
		list: {
			url: `${config.API_URL}/investor/list`,
			name: "获取投资人列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/investor/store`,
			name: "新增编辑投资人",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/investor/detail`,
			name: "获取投资人详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/investor/del`,
			name: "删除投资人",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
	place: {
		list: {
			url: `${config.API_URL}/place/list`,
			name: "获取场地方列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/place/store`,
			name: "新增编辑场地方",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/place/detail`,
			name: "获取场地方详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/place/del`,
			name: "删除场地方",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
	cooperation: {
		list: {
			url: `${config.API_URL}/business/index`,
			name: "获取商务合作列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/business/store`,
			name: "编辑商务合作",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/business/del`,
			name: "删除商务合作",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
};
