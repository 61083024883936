import config from "@/config";
import http from "@/utils/request";

export default {
	token: {
		url: `${config.API_URL}/passport/login`,
		name: "登录获取TOKEN",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		},
	},
	logout: {
		url: `${config.API_URL}/user/logout`,
		name: "退出登录",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		},
	},
};
