import API from "@/api";

//文件选择器配置

export default {
	apiObj: API.common.upload,
	listApiObj: API.common.file.list,
	menuApiObj: API.common.file.group.list,
	listDeleteObj: API.common.file.delete,
	successCode: 200,
	maxSize: 10,
	max: 99,
	uploadParseData: function (res) {
		return {
			id: res.data.id,
			fileName: res.data.fileName,
			url: res.data.src,
		};
	},
	listParseData: function (res) {
		return {
			rows: res.data.data,
			total: res.data.total,
			msg: res.message,
			code: res?.code,
		};
	},
	request: {
		page: "page",
		pageSize: "size",
		keyword: "keyword",
		menuKey: "admin_id",
		groupKey: "group_id",
	},
	menuProps: {
		key: "id",
		label: "group_name",
		children: "children",
	},
	fileProps: {
		key: "id",
		fileName: "file_name",
		groupKey: "group_id",
		url: "url",
	},
	files: {
		doc: {
			icon: "sc-icon-file-word-2-fill",
			color: "#409eff",
		},
		docx: {
			icon: "sc-icon-file-word-2-fill",
			color: "#409eff",
		},
		xls: {
			icon: "sc-icon-file-excel-2-fill",
			color: "#67C23A",
		},
		xlsx: {
			icon: "sc-icon-file-excel-2-fill",
			color: "#67C23A",
		},
		ppt: {
			icon: "sc-icon-file-ppt-2-fill",
			color: "#F56C6C",
		},
		pptx: {
			icon: "sc-icon-file-ppt-2-fill",
			color: "#F56C6C",
		},
	},
};
