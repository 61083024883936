/**
 * @description 自动import导入所有 Icon 模块
 */

const files = require.context("./", false, /\.vue$/);
const modules = {};
files.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.vue)/g, "")] = files(key).default;
});

export default modules;
