export default {
	mounted(el, binding) {
		const { value } = binding;
		const { permission } = binding.instance.$route.meta;
		if (value) {
			if (!permission || !permission[value]) {
				el.parentNode.removeChild(el);
			}
		}
	},
};
